@import '../../node_modules/primeflex/core/variables';

$header-height: 80px;
$banner-height: 42px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$fp-font-family:
  'Lato',
  sans-serif,
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  Helvetica,
  Arial,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol';

$fp-blue: #90afe8;
$fp-blue-light: #a6bce3;
$fp-blue-alpha: rgba(166, 188, 227, 0.22);
$fp-blue-dark: #819dd0;
$fp-blue-night: #0c0958;

$fp-orange: #eaa023;
$fp-orange-alpha: rgba(234, 160, 35, 0.3);
$fp-orange-light: #f2ebe5;
$fp-orange-dark: #de9415;

$fp-green: #5cafa0;
$fp-green-light: #7acabc;
$fp-green-lighter: rgba(122, 202, 188, 0.34);
$fp-green-dark: #006251;
$fp-green-alpha: rgba(99, 183, 168, 0.36);

$fp-red: #ee5551;
$fp-red-light: #f39fb9;
$fp-red-light-alpha: rgba(243, 159, 185, 0.43);

$fp-white: #ffffff;
$fp-grey: #ced4da;
$fp-grey-light: #dfdfdf;
$fp-grey-dark: #a6b2c3;

$fp-font-color: #495057;
$fp-background: #fbfbfc;
$primary-color: $fp-blue-light;

$border-style: 1px solid $fp-grey;

.fp-background-blue {
  background-color: $fp-blue !important;
}

.fp-font-white {
  color: $fp-white !important;
}

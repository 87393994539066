@import 'reset';
@import 'flags';
@import 'variables';
@import 'primeng';
@import 'fp-theme.scss';
@import 'primeflex/primeflex.css';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import '@typeform/embed/build/css/popup.css';
@import 'calendly-widget.scss';

// TYPO
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font: 400 18px/1.5 $fp-font-family;
  background: $fp-background;
  height: 100%;
  color: $fp-font-color;
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.75rem;
}

h2 {
  font-size: 1.2rem;
  font-weight: bold;
}

h3 {
  font-size: normal;
  font-weight: bold;
}

p {
  margin: 0 0 0.3rem 0;
}

a {
  color: $fp-font-color;
}

.text-xxxs {
  font-size: 0.5rem; // 9px
}

.text-xxs {
  font-size: 0.56rem; // 10px
}

.text-xs {
  font-size: 0.67rem !important; // 12px
  line-height: 1.2;
}

.text-14 {
  font-size: 14px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-20 {
  font-size: 20px;
}

.text-black {
  color: black;
}

.text-blue {
  color: $fp-blue-light;
}

.text-green {
  color: $fp-green-light;
}

.text-orange {
  color: $fp-orange;
}

.text-red {
  color: $fp-red;
}

.white-background {
  background: white;
}

.blue-background {
  background: $fp-blue-light !important;
}

.blue-light-background {
  background: $fp-blue-alpha !important;
}

.green-background {
  background: $fp-green-light !important;
}

.green-light-background {
  background: $fp-green-lighter !important;
}

.orange-background {
  background: $fp-orange !important;
}

.orange-light-background,
.yellow-back {
  background-color: $fp-orange-light !important;
}

.orange-back {
  background-color: rgba(234, 160, 35, 0.49) !important;
}

.pink-back {
  background-color: $fp-red-light-alpha !important;
}

.border-bottom {
  border-bottom: $border-style;
}

.border-radius-6 {
  border-radius: 6px;
}

.error-box {
  color: $fp-red;
  font-size: 0.67rem !important; // 12px
  line-height: 1.2;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  scale: 1.2;
}

.green-chip {
  font-size: 10px;
  background: $fp-green-lighter;
}

//LAYOUT

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 7px;
}

body::-webkit-scrollbar:horizontal {
  height: 7px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.2);
}

body.p-overflow-hidden,
body[style*='overflow: hidden'] {
  position: fixed;
  width: 100%;
}

.container {
  padding: 3rem 4rem 1.5rem 4rem;
}

@media only screen and (max-width: 1340px) {
  .container {
    padding: 1rem;
  }
}

@media only screen and (max-height: 768px) {
  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.flex-basis-20 {
  flex-basis: 20%;
}
.flex-basis-25 {
  flex-basis: 25%;
}
.flex-basis-30 {
  flex-basis: 30%;
}
.flex-basis-40 {
  flex-basis: 40%;
}
.flex-basis-45 {
  flex-basis: 45%;
}
.flex-basis-50 {
  flex-basis: 50%;
}
.flex-basis-60 {
  flex-basis: 60%;
}
.flex-basis-70 {
  flex-basis: 70%;
}
.flex-basis-80 {
  flex-basis: 80%;
}
.flex-basis-100 {
  flex-basis: 100%;
}
.card {
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  background-color: $fp-white;
  width: 100%;
  margin: 0 1rem 1rem 0;
  padding: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  transition: all 0.2s ease-in-out;
  line-height: 24px;
}
@media only screen and (max-width: calc($lg - 1px)) {
  .card {
    margin: 1rem 0 0 0;
  }
}

.red-card {
  background: #f39fb92b;
  border: 1px solid $fp-red-light;
}

iframe {
  width: 100%;
  height: 100vh;
  padding: 0;
  border: none;
}

@media only screen and (min-width: $xl) {
  iframe {
    height: calc(100vh - $header-height - 7px);
    overflow-y: scroll;
  }

  .right-container.vis-banner iframe {
    height: calc(100vh - $header-height - $banner-height - 7px);
  }
}

.container-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 75%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

// MODULES

.p-editor {
  width: 100%;
}

.ql-editor {
  font-family: 'Lato', sans-serif;
}

.ql-container {
  font-size: 0.875rem;
}

.ql-editor strong {
  font-weight: bold;
}

.ql-editor em {
  font-style: italic;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  width: 22px;
  padding-left: 0;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
}

.ql-editor.ql-blank::before {
  content: none;
}

.ql-editor.ql-blank::after {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  display: block;
  font-style: italic;
  margin-top: -1.42em;
  min-height: 1em;
  pointer-events: none;
}

.p-editor-hide-toolbar:not(:focus-within) {
  height: 100%;

  .p-editor-container {
    height: 100%;
  }
  .p-editor-container .p-editor-toolbar.ql-snow {
    display: none;
  }
}

.p-confirm-popup {
  max-width: 80%;
}

.hyphens {
  hyphens: auto;
}

@media only screen and (min-width: $md) {
  .p-confirm-popup {
    max-width: 50vw;
  }
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0;
  border: 0;
  background-color: white;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background-color: white !important;
}

.p-tooltip {
  max-width: fit-content;
}

.p-tooltip-text {
  font-size: 0.75rem;
}

.p-panelmenu .p-icon-wrapper,
.p-panelmenu .p-icon-wrapper + .p-menuitem-text,
.p-panelmenu .p-panelmenu-content {
  position: relative;
  left: -26px;
}

.p-panelmenu .p-panelmenu-content {
  width: calc(100% + 26px);
}

.p-panelmenu .p-panelmenu-header.p-highlight > .p-menuitem-link-active,
.p-menuitem-link-active {
  font-weight: bold;
  background: $fp-orange-light;
}

.p-panelmenu .p-panelmenu-header.p-highlight .p-submenu-list .p-menuitem-link-active,
.p-submenu-list .p-menuitem-link-active {
  font-weight: bold;
  background: none;
}

.p-sidebar-left.p-sidebar-active {
  width: 220px;
}

.p-blockui {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.p-progress-spinner-circle {
  stroke: $fp-blue-light !important;
}

.p-dialog-header {
  position: relative;
  bottom: -1px;
}

/* Fix Input Zoom on iPhone */
@media screen and (max-width: $sm) {
  textarea.text-xs,
  input.text-xs,
  textarea.text-sm,
  input.text-sm {
    font-size: 16px !important;
  }
}

.tooltip-icon {
  color: $fp-blue-light;
  font-size: 0.875rem;
  top: -7px;
}

.p-button.p-button-redirect,
.p-buttonset.p-button-redirect > .p-button,
.p-splitbutton.p-button-redirect > .p-button {
  color: $fp-font-color;
  background: $fp-orange-light;
  border: 1px solid $fp-orange-light;
}
.p-button.p-button-redirect:hover,
.p-button.p-button-redirect:enabled:hover,
.p-buttonset.p-button-redirect > .p-button:enabled:hover,
.p-splitbutton.p-button-redirect > .p-button:enabled:hover {
  background: $fp-orange;
  color: $fp-font-color;
  border-color: $fp-orange;
}
.p-button.p-button-redirect:focus,
.p-button.p-button-redirect:enabled:focus,
.p-buttonset.p-button-redirect > .p-button:enabled:focus,
.p-splitbutton.p-button-redirect > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem $fp-orange;
}
.p-button.p-button-redirect:active,
.p-button.p-button-redirect:enabled:active,
.p-buttonset.p-button-redirect > .p-button:enabled:active,
.p-splitbutton.p-button-redirect > .p-button:enabled:active {
  background: $fp-orange;
  color: $fp-font-color;
  border-color: $fp-orange;
}

.p-orderlist .p-orderlist-controls .p-button {
  background: $fp-orange;
  color: #000;
  border: 1px solid $fp-orange;
}

.p-orderlist .p-orderlist-controls .p-button:hover {
  background: $fp-orange-dark;
}

.p-orderlist .p-orderlist-controls .p-button:focus {
  box-shadow: $fp-orange;
}

.cdk-drag .p-tag {
  background: $fp-orange !important;
}

.p-datepicker-other-month {
  cursor: default;
}

/* /* Eye icon is on the right side of password box */
.p-password .p-icon-wrapper {
  right: 5%;
}
